import Collaborateur from '@/models/collaborateurs/Collaborateur'
import CollaborateurContrat from '@/models/collaborateurs/CollaborateurContrat'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'
import Invitation from '@/models/users/Invitation'

/**
* @Name CollaborateursApiClientUrlsInterface
* @description
* Interface for the Collaborateurs urls used to avoid hard-coded strings
*/
export interface CollaborateursApiClientUrlsInterface {
    getCollaborateurs: string
    getCollaborateur: string
    postCollaborateur: string
    putCollaborateur: string
    deleteCollaborateur: string
    inviterUtilisateur: string
    getCollaborateurContrats: string
    getCollaborateurContrat: string
    postCollaborateurContrat: string
    putCollaborateurContrat: string
    deleteCollaborateurContrat: string
}

/**
* @Name CollaborateursApiClientInterface
* @description
* Interface for the Collaborateurs api client module
*/
export interface CollaborateursApiClientInterface {
    getCollaborateurs: (apiFilters?: any) => Promise<Collaborateur[]>
    getCollaborateur: (collaborateur: Collaborateur, apiFilters?: any) => Promise<Collaborateur>
    sendCollaborateur: (collaborateur: Collaborateur) => Promise<Collaborateur>
    deleteCollaborateur: (collaborateur: Collaborateur) => Promise<Collaborateur>

    inviterUtilisateur: (invitation: Invitation) => Promise<Collaborateur>

    getCollaborateurContrats: (apiFilters?: any) => Promise<CollaborateurContrat[]>
    getCollaborateurContrat: (collaborateur: CollaborateurContrat, apiFilters?: any) => Promise<CollaborateurContrat>
    sendCollaborateurContrat: (collaborateur: CollaborateurContrat) => Promise<CollaborateurContrat>
    deleteCollaborateurContrat: (collaborateur: CollaborateurContrat) => Promise<CollaborateurContrat>
}

/**
* @Name CollaborateursApiClientModel
* @description
* Implements the CollaborateursApiClientInterface interface
*/
export class CollaborateursApiClientModel implements CollaborateursApiClientInterface {
    private readonly urls!: CollaborateursApiClientUrlsInterface
    constructor(urls: CollaborateursApiClientUrlsInterface) {
        this.urls = urls
    }

    getCollaborateurs(apiFilters?: any): Promise<Collaborateur[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getCollaborateurs,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<Collaborateur[]>(getParameters)
    }

    getCollaborateur(collaborateur: Collaborateur,apiFilters?: any): Promise<Collaborateur> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getCollaborateur.replace('$(collaborateurId)', collaborateur.id!),
            requiresToken: false,
            filters: apiFilters
        }
        return HttpClient.get<Collaborateur>(getParameters)
    }

    sendCollaborateur(collaborateur: Collaborateur): Promise<Collaborateur> {
        if (collaborateur.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putCollaborateur.replace('$(collaborateurId)', collaborateur.id!),
                requiresToken: false,
                payload: collaborateur,
            }
            return HttpClient.put<Collaborateur>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postCollaborateur.replace('$(collaborateurId)', collaborateur.id!),
                requiresToken: false,
                payload: collaborateur,
            }
            return HttpClient.post<Collaborateur>(getParameters)
        }
    }

    deleteCollaborateur(collaborateur: Collaborateur): Promise<Collaborateur> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteCollaborateur.replace('$(collaborateurId)', collaborateur.id!),
            requiresToken: false,
        }
        return HttpClient.delete<Collaborateur>(getParameters)
    }

    inviterUtilisateur(invitation: Invitation): Promise<Collaborateur> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.inviterUtilisateur,
            requiresToken: false,
            payload: invitation,
        }
        return HttpClient.post<Collaborateur>(getParameters)
    }

    getCollaborateurContrats(apiFilters?: any): Promise<CollaborateurContrat[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getCollaborateurContrats,
            requiresToken: false,
            filters: apiFilters,
        }
        return HttpClient.get<CollaborateurContrat[]>(getParameters)
    }

    getCollaborateurContrat(collaborateurContrat: CollaborateurContrat, apiFilters?: any): Promise<CollaborateurContrat> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getCollaborateurContrat.replace('$(collaborateurContratId)', collaborateurContrat.id!),
            requiresToken: false,
            filters: apiFilters
        }
        return HttpClient.get<CollaborateurContrat>(getParameters)
    }

    sendCollaborateurContrat(collaborateurContrat: CollaborateurContrat): Promise<CollaborateurContrat> {
        if (collaborateurContrat.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putCollaborateurContrat.replace('$(collaborateurContratId)', collaborateurContrat.id!),
                requiresToken: false,
                payload: collaborateurContrat,
            }
            return HttpClient.put<CollaborateurContrat>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postCollaborateurContrat.replace('$(collaborateurContratId)', collaborateurContrat.id!),
                requiresToken: false,
                payload: collaborateurContrat,
            }
            return HttpClient.post<CollaborateurContrat>(getParameters)
        }
    }

    deleteCollaborateurContrat(collaborateurContrat: CollaborateurContrat): Promise<CollaborateurContrat> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteCollaborateurContrat.replace('$(collaborateurContratId)', collaborateurContrat.id!),
            requiresToken: false,
        }
        return HttpClient.delete<Collaborateur>(getParameters)
    }
}
