import Group from "../users/Group"

export default class Utilisateur {
  id: string
  email: string
  statut?: number
  is_active: boolean
  groups: any[]
  collaborateur: any
  user_permissions: any[]

  constructor() {
    this.id = 'notSet';
    this.email = '';
    this.statut = undefined;
    this.is_active = true;
    this.groups = [];
    this.user_permissions = [];
  }

  get prenom(): string {
    return this.collaborateur ? this.collaborateur.prenom : '';
  }

  get nom(): string {
    return this.collaborateur ? this.collaborateur.nom : '';
  }

  get fullName(): string {
    return this.collaborateur.prenom && this.collaborateur.nom ? this.collaborateur.nom + " " + this.collaborateur.prenom : '';
  }

  get statutTexte(): string {
    switch (this.statut) {
      case 0:
        return 'Invité';
      case 1:
        return 'Validé';
      case 2:
        return "Mail non validé"
      default:
        return 'error';
    }
  }

  get allGroupsNames(): string {
    let res = ""
    this.groups.forEach((group, index, array) => {
      if(group.name != "GU-COLLABORATEURS"){
        res += group.name;
        if (index != (array.length - 1)) {
          res += ' | ';
        }
      }
    });
    return res;
  }
}