import Environnement from "./Environnement";

export default class Visite {
    id: string
    list: string[];
    id_card?: string;
    proof_of_residence?: string;
    recent_tax_notice?: string;
    plans_technical_descriptions?: string;
    energy_water?: string;
    maintenance_contract?: string;
    client_documents?: string;
    description_of_work?: string;
    claim_declaration?: string;
    other_document?: string;

    metrage?: string;  // Métré (loi BOUTIN) si absence de plan utilisable
    dpe?: string;  // DPE (Diagnostic de Performance Énergétique)
    gaz?: string;
    electricite?: string;
    amiante?: string;
    plomb?: string;
    risques_pollutions?: string;  // État des Risques et Pollutions (ERNMT) (PPR, PPRN, sismique +)
    radon?: string;
    etat_parasitaire?: string;
    assainissement?: string;
    acoustique?: string;
    zone_bruit?: string;
  
    constructor() {
      this.id = 'notSet';
      this.list = [];

    }

}