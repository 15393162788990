

export default class Collaborateur {
  id?: string;
  nom?: string;
  prenom?: string;
  telephone?: string;
  email?: string;
  email_pro?: string;
  is_utilisateur: boolean;
  user?: any;

  constructor() {
    this.id = 'notSet';
    this.is_utilisateur = false;
  }



  get fullName(): string {
    return this.prenom + ' ' + this.nom;
  }
  get collaborateurFullName(): string {
    return this.prenom + ' ' + this.nom;
  }

  get inCreation(): boolean {
    return this.id == 'notSet';
  }

  

  get initiales(): string {
    if(this.nom != undefined && this.prenom != undefined){
      return this.nom![0].toLocaleUpperCase() + this.prenom![0].toLocaleUpperCase();
    }
    return ""
  }


  get isActive(): boolean {
    return this.isActive;
  }
}