import Bien from "../biens/Bien";
import Projet from "../projets/Projet";
import Environnement from "./Environnement";

export default class Visite {
    id: string
    nom?: string;
    prenom?: string;
    telephone?: string;
    mail?: string;
    adresse?: string;
    code_postal?: string;
    ville?: string;
    is_proprietaire: boolean;
    adresse_chantier?: string;
    nombre_personnes_logement?: number;
    revenu_fiscal_reference?: number;
    environnement?: any;
    createur?: any;
    documents?: any;
    projet?: any;
    bien?: any;
  
    constructor() {
      this.id = 'notSet';
      this.nom = undefined;
      this.prenom = undefined;
      this.telephone = undefined;
      this.mail = undefined;
      this.adresse = undefined;
      this.code_postal = undefined;
      this.ville = undefined;
      this.is_proprietaire = true;
      this.nombre_personnes_logement = 1;
      this.revenu_fiscal_reference = undefined;
      this.environnement = new Environnement();
      this.createur = undefined;
      this.projet = new Projet();
      this.bien = new Bien();
      this.documents = new Document();
    }

    get fullAdresse(): string {
      if (this.adresse_chantier) return this.adresse_chantier;
      return `${this.adresse} ${this.code_postal} ${this.ville}`;
    }
}